<template>
  <div>
    <v-form>
      <p class="mb-3">
        Titration curves labelled A, B, C and D for combinations of different aqueous solutions of
        acids and bases are shown below. Note that all solutions have a concentration of
        <stemble-latex content="$0.1\,\text{mol/dm}^{3}.$" />
      </p>

      <p>
        <img :src="imageName" width="500px" alt="" class="pl-2 mb-4" />
      </p>

      <v-simple-table>
        <v-row class="pt-3">
          <v-col cols="4">
            <p>a) Ammonia added to hydrochloric acid</p>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="inputs.input1"
              class="d-sm-inline-block ml-3 mr-3"
              style="width: 360px"
              dense
              :items="items"
              label="Select Option"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <p>b) Sodium hydroxide added to ethanoic acid</p>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="inputs.input2"
              class="d-sm-inline-block ml-3 mr-3"
              style="width: 360px"
              dense
              :items="items"
              label="Select Option"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <p>c) Nitric Acid added to potassium hydroxide</p>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="inputs.input3"
              class="d-sm-inline-block ml-3 mr-3"
              style="width: 360px"
              dense
              :items="items"
              label="Select Option"
            />
          </v-col>
        </v-row>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OleMiss116_PL9_Q2part5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      items: [
        {text: 'Titration Curve A', value: 'a'},
        {text: 'Titration Curve B', value: 'b'},
        {text: 'Titration Curve C', value: 'c'},
        {text: 'Titration Curve D', value: 'd'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/ChemOleMiss116PL9Q5_header.png';
    },
  },
};
</script>
